// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cazare-js": () => import("./../src/pages/cazare.js" /* webpackChunkName: "component---src-pages-cazare-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-templates-hotel-js": () => import("./../src/templates/hotel.js" /* webpackChunkName: "component---src-templates-hotel-js" */),
  "component---src-templates-hotels-type-js": () => import("./../src/templates/hotels-type.js" /* webpackChunkName: "component---src-templates-hotels-type-js" */)
}

